import React from "react";


class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-section">
                <div className="footer-container">
                    <div className="footer-row">
                        <div className="footer-logo">
                            <a href="/" title="Home">
                                <img src="images/edalogo.png" alt="EDA College Logo" className="footer-logo-image" />
                            </a>
                        </div>
                        <div className="footer-links">
                            <h6 className="footer-heading">Resources</h6>
                            <ul className="footer-link-list">
                                <li><a href="/accreditation-and-affiliation">Accreditation and Affiliations</a></li>
                                <li><a href="/Contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="footer-contact">
                            <h6 className="footer-heading">Contact Us</h6>
                            <p className="footer-contact-info">info@edacollege.co.uk</p>
                            <p className="footer-contact-info">+44 2039 300478</p>
                            <div className="footer-social">
                                <ul className="social-icons">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://x.com/EDACollege?t=_LXcTcEBjsR6_J3I_xVpQg&s=09"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-google"></i></a></li>
                                    <li><a href="#"><i className="fab fa-apple"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <span className="back-to-top" data-href="#">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 551.13 551.13">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-1d98"></use>
                    </svg>
                    <svg className="u-svg-content" enableBackground="new 0 0 551.13 551.13" viewBox="0 0 551.13 551.13" xmlns="http://www.w3.org/2000/svg" id="svg-1d98">
                        <path d="m275.565 189.451 223.897 223.897h51.668l-275.565-275.565-275.565 275.565h51.668z"></path>
                    </svg>
                </span>
                </div>
               
            </footer>
        );
    }
}

export default Footer;
